import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/* 
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */

@Module({ namespaced: true })
export default class SearchMessageSentStore extends VuexModule {
  // campaign: string | null = null // todo not present in controller
  // tags: string | null = null // todo not present in controller
  messageTitle: string | null = null
  creationDateFrom: string | null = null
  creationDateTo: string | null = null
  sendingChannel: string | null = null
  messageType: string | null = null
  deliveryStatus: string | null = null
  recipientFullName: string | null = null
  attachments: boolean | null = null
  read: boolean | null = null
  reduced: boolean | null = null
  tags: string | null = null

  useTerms = false
  items: TermItemDefinition[] = [
    { key: 'messageTitle', operation: '~' },
    { key: 'messageType', operation: ':' },
    { key: 'recipientFullName', operation: '~' },
    { key: 'deliveryStatus', operation: ':', itemType: 'string' },
    { key: 'creationDateFrom', operation: ':' },
    { key: 'creationDateTo', operation: ':' },
    { key: 'sendingChannel', operation: ':' },
    { key: 'attachments', operation: ':', itemType: 'boolean' },
    { key: 'read', operation: ':', itemType: 'boolean' },
    { key: 'reduced', operation: ':', itemType: 'boolean' },
    { key: 'tags', operation: ':', itemType: 'string' }
  ]
}
