import { Module, VuexModule } from 'vuex-module-decorators'
import { TermItemDefinition } from '@/services/term'

/*
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchDocumentStore extends VuexModule {
  documentTypeGroup: string | null = null
  legalEntityId: number | null = null
  filename: string | null = null
  type: string | null = null
  from: string | null = null
  until: string | null = null
  items: TermItemDefinition[] = [{ key: 'documentTypeGroup', operation: ':' }]
}
