import { TermItemDefinition } from '@/services/term'
import moment from 'moment'
import { Module, VuexModule } from 'vuex-module-decorators'

/*  status: string | null = null
 */
@Module({ namespaced: true })
export default class SearchMilkQualityContentStore extends VuexModule {
  mbhIdent: number | null = null
  animalTypeId: number | null = 1
  ordered: boolean | null = null
  contentDate: string | null = moment().format('YYYY-MM-DD')
  producerNumber: string | null = null

  items: TermItemDefinition[] = [
    { key: 'mbhIdent', operation: ':' },
    { key: 'animalTypeId', operation: ':', defaultValue: 1 },
    { key: 'ordered', operation: ':' },
    { key: 'contentDate', operation: ':', defaultValue: moment().format('YYYY-MM-DD') },
    { key: 'producerNumber', operation: ':' }
  ]
}
