import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*
namespaced:
store.commit('DebugStore/setDebug'
computed: {
    ...mapGetters('SearchStore', ['ApplicationUser_agateId'])
    },
 */
@Module({ namespaced: true })
export default class SearchProductionReportStore extends VuexModule {
  statusFrom: number | null = null
  statusTo: string | null = null
  correction: boolean | null = null
  validFrom: string | null = null
  validUntil: string | null = null
  animalTypeId: number | null = null

  items: TermItemDefinition[] = [
    { key: 'statusFrom', operation: ':' },
    { key: 'statusTo', operation: ':' },
    { key: 'correction', operation: ':', itemType: 'boolean' },
    { key: 'validFrom', operation: '>=' },
    { key: 'validUntil', operation: '<=' },
    { key: 'animalTypeId', operation: ':' }
  ]
}
