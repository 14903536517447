import { app, apiURL, emitter } from '@/main'
import { showError } from './axios'
import { privileges } from '@/services/privileges'
import { Person } from '@/domain/person'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { LegalEntities } from '@/domain/LegalEntities'

export class Persons {
  // Its to early in the startup and causes Error: Cannot access 'useLegalEntityStore' before initialization
  // private legalEntityStore = useLegalEntityStore()

  private static _instance: Persons
  public applicationUser: any = {}
  public legalEntities: any = []

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  ready: boolean = false

  oidcUserLoaded() {
    personsService
      .checkInUser()
      .then(async (checkInUser) => {
        const legalEntityStore = useLegalEntityStore()
        legalEntityStore.incLoaders()
        const legalEntities = await personsService.setLegalEntity(checkInUser)
        if (legalEntities) {
          await privileges.load()
          emitter.emit('reloadNav')
          legalEntityStore.decLoaders()
          return true
        }
      })
      .catch((e) => {
        console.log(e)
        const legalEntityStore = useLegalEntityStore()
        legalEntityStore.decLoaders()
        return false
      })
  }

  init(applicationUser: any, legalEntities: Person[]) {
    this.applicationUser = applicationUser
    this.legalEntities = legalEntities
  }

  public async checkInUser() {
    const legalEntityStore = useLegalEntityStore()

    try {
      legalEntityStore.incLoaders()
      const requested: any = await app.axios.put(apiURL + '/applicationUsers/checkIn/')
      //set entire obj
      this.applicationUser = requested.data
      legalEntityStore.setApplicationUser(this.applicationUser)

      //set legalentities
      this.legalEntities = this.applicationUser.allowedLegalEntities
      legalEntityStore.setLegalEntities(this.legalEntities)

      //calculate any forceDomain
      this.checkForceDomainSelection()

      //this.applicationUser.thisLogin = new Date().toISOString
      if (!(this.getSelectedApplicationUserId() > 0)) {
        // has no user-switch
        await app.axios.patch(apiURL + '/applicationUsers/updateLastLogin')

        emitter.emit('reloadNav')
        legalEntityStore.decLoaders()
        return this.applicationUser
      }
    } catch (e) {
      console.error(e)
      legalEntityStore.decLoaders()
      return {}
    }
  }

  async loadLegalEntity(checkInUser: any, forced: boolean): Promise<Person[]> {
    const legalEntityStore = useLegalEntityStore()
    if (legalEntityStore.legalEntities.length > 0 && !forced) {
      return legalEntityStore.legalEntities
    }
    legalEntityStore.incLoaders()
    try {
      const response = await app.axios.get(apiURL + '/privileges/legalEntities') // refactor at some point
      const data = await response.data
      const legalEntities: LegalEntities[] = data
      legalEntityStore.setLegalEntities(legalEntities)
      this.legalEntities = legalEntities
    } finally {
      legalEntityStore.decLoaders()
    }
    return this.setLegalEntity(checkInUser)
  }

  async setLegalEntity(checkInUser: any): Promise<Person[]> {
    const legalEntityStore = useLegalEntityStore()

    if (!checkInUser.lastSelectedLegalEntityId) {
      // first time
      console.log('checkin no lastSelectedLegalEntityId')
      legalEntityStore.setDomain(this.legalEntities[0].id)
    } else if (this.legalEntities.find((p) => p.id == checkInUser.lastSelectedLegalEntityId)) {
      //unchanged?
      console.log('checkin number validated')
      legalEntityStore.setDomain(checkInUser.lastSelectedLegalEntityId)
    } else {
      // le structure changed lasteSelected not accurate anymore
      console.log('checkin not validated, fallback to first legalEntityId:', this.legalEntities[0].id)
      legalEntityStore.setDomain(this.legalEntities[0].id)
    }
    this.ready = true
    return this.legalEntities
  }

  getSelectedPerson() {
    const legalEntityStore = useLegalEntityStore()
    return legalEntityStore.getSelectedPersonId
  }

  getSelectedPersonName() {
    const legalEntityStore = useLegalEntityStore()
    if (this.getSelectedApplicationUserId() > 0) {
      return legalEntityStore.legalEntities[0]?.name1
    } else {
      return ''
    }
  }

  getSelectedApplicationUserId() {
    const legalEntityStore = useLegalEntityStore()
    return legalEntityStore.selectedApplicationUserId
  }

  getSelectedApplicationUserName() {
    const legalEntityStore = useLegalEntityStore()
    return legalEntityStore.selectedApplicationUserName
  }

  /** set/change own domain */
  async setDomain(domainId: number) {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.setDomain(domainId)
    try {
      if (!personsService.getSelectedApplicationUserId()) {
        legalEntityStore.incLoaders()
        await app.axios.patch(apiURL + '/applicationUsers/' + this.applicationUser.id + '/updateLastSelectedLegalEntity?legalEntityId=' + domainId)
      }
    } catch (e) {
      console.log(e)
    } finally {
      legalEntityStore.decLoaders()
      this.resetStoresPreserveUser()
      await privileges.load()
      emitter.emit('reloadNav')
      emitter.emit('routePushHome')
    }
  }

  async impersonateAsUser(selectedApplicationUser: any) {
    console.log('impersonate as user', selectedApplicationUser)
    if (selectedApplicationUser.applicationUserId) {
      selectedApplicationUser.id = selectedApplicationUser.applicationUserId
      selectedApplicationUser.name = selectedApplicationUser.applicationUserName
      selectedApplicationUser.email = selectedApplicationUser.applicationUserEmail
      selectedApplicationUser.allowedLegalEntities = [
        {
          id: selectedApplicationUser.legalEntityId,
          name1: selectedApplicationUser.legalEntityName1
        }
      ]
    }
    this.resetStoresPreserveUser()
    const legalEntityStore = useLegalEntityStore()

    if (selectedApplicationUser == null) {
      legalEntityStore.setSelectedApplicationUser(undefined)
      legalEntityStore.setDomain(null)
    } else {
      legalEntityStore.setSelectedApplicationUser(selectedApplicationUser)
    }
    await privileges.load()
    this.loadLegalEntity(selectedApplicationUser, true)
    emitter.emit('reloadNav')
    emitter.emit('routePushHome')
  }

  /* switch to another userDomain */
  async switchDomain(legalEntity: any) {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.switchDomain(legalEntity)
    this.resetStoresPreserveUser()
    await privileges.load()
    emitter.emit('reloadNav')
    emitter.emit('routePushHome')
    return true
  }

  async switchDomainAsSelectedUser(legalEntityId: number) {
    const legalEntityStore = useLegalEntityStore()
    //app.$store.commit('setDomain', legalEntityId)
    legalEntityStore.setDomain(legalEntityId)
    this.resetStoresPreserveUser()
    await privileges.load()
    emitter.emit('reloadNav')
    emitter.emit('routePushHome')
    return true
  }

  /* switch to another userDomain */
  async backToOriginDomain() {
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.resetDomain()
    this.resetStoresPreserveUser()

    this.loadLegalEntity(this.applicationUser, true)
    //this.setLegalEntity(this.applicationUser)
    await privileges.load()
    emitter.emit('reloadNav')
    emitter.emit('routePushHome')
    return true
  }

  resetStoresPreserveUser() {
    const somePiniaStore = useLegalEntityStore()
    somePiniaStore.$reset_all_preserve(['legalEntityStore'])
  }

  reset() {
    this.ready = false
    this.applicationUser = {}
    this.legalEntities = []
    const legalEntityStore = useLegalEntityStore()
    legalEntityStore.setLegalEntities([])

    //app.$store.commit('setDomain', 0)
    legalEntityStore.setDomain(0)

    try {
      delete app.axios.defaults.headers.common['Impersonation']
    } catch (e) {
      showError(e)
    }
  }

  checkForceDomainSelection() {
    if (this.legalEntities.length <= 1) return false // no selection required when only one option
    if (this.applicationUser?.lastLogin == null || this.applicationUser?.lastSelectedLegalEntityId == null) {
      emitter.emit('forceDomainSelection', true) // no selection required if no last login record
    }
    if (this.applicationUser.lastLogin.substring(0, 10).localeCompare(new Date().toISOString().substring(0, 10)) != 0) {
      emitter.emit('forceDomainSelection', true) // selection required if last login >1day ago
    }
  }
}

export const personsService = Persons.Instance
