<template>
  <div v-if="loading" class="header-bar-right margin-left center-vertically" id="languageSelector">
    <v-progress-circular indeterminate></v-progress-circular>
  </div>
  <div v-else class="language-width">
    <v-btn id="lang-activator" class="noborder"> <v-icon dbmpassivgrey>mdi-web</v-icon> {{ selectedLanguage.toUpperCase() }} </v-btn>

    <v-menu activator="#lang-activator">
      <v-list>
        <v-list-item v-for="(item, index) in availableLanguages" :key="index" :value="index"
          ><v-list-item-title @click="setSelectedItem(item)">{{ item }}</v-list-item-title></v-list-item
        >
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { emitter } from '@/main'
import { Languages, language } from '../services/language'

export default defineComponent({
  data() {
    return {
      languageService: language,
      loading: false
    }
  },
  methods: {
    setLanguageLoading(val: boolean) {
      this.loading = val
    },
    setSelectedItem(newValue) {
      let low = newValue.toLowerCase()
      this.$router.push({ name: this.$route.name, params: { ...this.$route.params, lang: low }, query: this.$route.query }).catch(function (e) {
        showError(e)
      })
    }
  },
  computed: {
    availableLanguages() {
      return Languages.map((lang) => lang.toUpperCase())
    },
    loadingLanguage() {
      return this.languageService.loading === true ? true : false
    },
    selectedLanguage: {
      get() {
        if (this.$route?.params?.lang) return this.$route?.params?.lang
        return 'de'
      }
    }
  },
  mounted() {
    emitter.on('setLanguageLoading', this.setLanguageLoading)
  }
})
</script>

<style lang="scss" scoped>
.language-width {
  width: 60px;
}
</style>
