<template>
  <div class="d-print-none" v-if="privileges">
    <v-container id="topnav_person" class="container-xl container keepInside">
      <div class="header-bar-left" id="logo">
        <img
          src="../assets/images/bdlaitch_rgb.svg"
          alt="dbmilch"
          class="logo-size"
          height="48"
          width="200"
          v-if="languageKey === 'fr' || languageKey === 'it'"
        />
        <img src="../assets/images/dbmilchch_rgb.svg" alt="dbmilch" class="logo-size" height="48" width="200" v-else />
      </div>
      <div class="header-bar-right margin-left center-vertically" id="profile">
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <span class="pointer" v-bind="props" id="profileMenu">
              <v-icon dbmpassivgrey>mdi-dots-horizontal</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-subheader v-if="isAuthenticated">{{ userName }}</v-list-subheader>
            <v-list-subheader v-else>...</v-list-subheader>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title"><span v-html="$t('system_menu_user_account')" /></v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              :to="{
                name: 'profile',
                params: { lang: lang, tab: 'user' }
              }"
            >
              <v-list-subheader id="menuProfile"><span v-html="$t('system_menu_profile')" /> </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-subheader class="pointer" id="menuLogout" @click.prevent="signOut()" v-if="isAuthenticated">
                <span v-html="$t('system_menu_logout')" />
              </v-list-subheader>
              <v-list-subheader id="menuLogin" @click.prevent="login()" v-else><span v-html="$t('system_menu_login')" /></v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title"><span v-html="$t('system_menu_service')" /></v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-subheader class="pointer" id="menuHelpContact" @click.prevent="gotoExternalUrl($t('url_help_contact'))">
                <span v-html="$t('system_menu_help_contact')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-subheader class="pointer" id="menuInstructions" @click.prevent="gotoExternalUrl($t('url_instructions'))">
                <span v-html="$t('system_menu_instructions')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <!-- webpack will inject a timestamp check <html lang="en" data-build-timestamp-utc="<%= new Date().toISOString() %>"> in index.html  -->
              <v-list-subheader id="version">Build {{ builded }}</v-list-subheader>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="header-bar-right margin-left mt-2" id="language">
        <LanguageSelector />
      </div>
      <div class="header-bar-right margin-left center-vertically" id="search" v-if="$privileges.has({ path: '/masterdata', permission: 'read' })">
        <a class="link-right pointer" @click.prevent="search()">
          <v-icon right>mdi-magnify</v-icon>
          <span v-html="$t('search')"
        /></a>
      </div>
      <div class="header-bar-right margin-left center-vertically" id="logout" v-if="isAuthenticated">
        <a class="link-right pointer" @click.prevent="signOut()" v-html="$t('logout')"></a>
      </div>
      <div class="header-bar-right margin-left center-vertically" id="login" v-else>
        <a class="link-right pointer" @click.prevent="login()" v-html="$t('login')"></a>
      </div>
      <v-dialog persistent v-model="loginBlocker" width="800px">
        <v-card>
          <v-card-title>
            <span v-html="$t('system_pls_login_dialog_title')" />
          </v-card-title>
          <v-card-text>
            <span v-html="$t('system_pls_login_dialog_text')" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click.prevent="login()"><span v-html="$t('system_pls_login_dialog_action_login')" /></v-btn>
            <v-btn color="secondary" :to="{ name: 'home', params: { lang: lang } }">
              <span v-html="$t('system_pls_login_dialog_action_home')" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DomainSelector />
    </v-container>

    <div class="navigation-borders">
      <div class="container-xl container pb-0 pt-0">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link v-if="ready" class="menu-padding navbar-brand home-color" :to="{ name: 'home', params: { lang: lang } }"> HOME </router-link>
          <v-progress-linear v-if="privileges.loading" indeterminate></v-progress-linear>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavLightDropdown"
            aria-controls="navbarNavLightDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <!-- <span class="navbar-toggler-icon"></span> -->
            <v-icon dbmpassivgrey>mdi-menu</v-icon>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavLightDropdown">
            <ul class="navbar-nav">
              <li
                v-for="item in navTree"
                v-bind:key="item.label[languageKey]"
                :class="item.selectedPath ? 'level0 nav-item dropdown menu-font selectedPath' : 'level0 nav-item dropdown menu-font'"
                v-show="item.visibility"
              >
                <a
                  class="nav-link menu-padding home-color"
                  href="#"
                  :id="'level0_' + item.label[languageKey]"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <!-- <button> -->
                  {{ upper(item) }}
                  <!-- <i class="fa fa-caret-down"></i>
                </button> -->
                </a>
                <ul class="dropdown-menu dropdown-menu-light" :aria-labelledby="'level0_' + item.label[languageKey]" :id="'Flyout_' + item.label[languageKey]">
                  <div class="container-xl container pb-0 pt-0 left-margin-and-padding-less">
                    <li v-for="(child, indx) in item.children" v-bind:key="'child1_' + indx" v-show="child.visibility" class="menu-column">
                      <a
                        :class="child.selectedPath ? 'dropdown-item custom-dropdown-item-title selectedPath' : 'dropdown-item custom-dropdown-item-title'"
                        href="#"
                        :id="'level1_' + child.label[languageKey]"
                        role="button"
                        >{{ child.label[languageKey] }}</a
                      >

                      <ul class="dropdown-entry dropdown-entry-hover" :aria-labelledby="'level1_' + item.label[languageKey]">
                        <li v-for="(child2, indx2) in child.children" v-bind:key="'child2_' + indx2" v-show="child2.visibility">
                          <a @click="gotoLegacyNav(child2.route)" v-if="child2.legacy">{{ child2.label[languageKey] }}</a>
                          <router-link :class="child2.selectedPath ? 'selectedPath' : ''" :to="languagePrefix + child2.route" v-else>
                            {{ child2.label[languageKey] }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { navTree, NavTreeElement } from '@/router/navTree'
import LanguageSelector from '@/components/languageSelector.vue'
import { privileges } from '@/services/privileges'
import { Persons } from '@/services/persons'
import { emitter } from '@/main'

import { authService } from '@/services/auth.service'

import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { format } from 'date-fns'
import DomainSelector from '@/views/DomainSelector.vue'

export default defineComponent({
  name: 'navigation',
  components: {
    LanguageSelector: LanguageSelector,
    DomainSelector
  },
  data() {
    return {
      privileges: privileges,
      navTree: navTree as NavTreeElement[],
      routeInfo: { path: 'nope' },
      showCollapsedMenu: false,
      selectedMegaMenu: '',
      personsService: Persons.Instance,
      personsError: false,
      ready: false
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    isAuthenticated() {
      return typeof authService == 'object'
    },
    builded() {
      return format(document.documentElement.dataset.buildTimestampUtc, 'dd.MM.yyyy HH:mm')
    },
    loginBlocker() {
      return !this.isAuthenticated
    },
    languageKey() {
      return this.$route.params.lang
    },
    languagePrefix() {
      return '/' + this.$route.params.lang
    },
    userName() {
      if (authService.loadedUser?.profile?.family_name) {
        return authService.loadedUser.profile.family_name
      }
      if (authService.loadedUser?.profile?.given_name) {
        return authService.loadedUser.profile.given_name
      }
      if (authService.loadedUser?.profile?.email) {
        return authService.loadedUser.profile.email
      }
      return ' - '
    }
  },
  methods: {
    cleanup() {
      this.personsService.reset()
      //todo
      let somePiniaStore = useLegalEntityStore()
      somePiniaStore.$reset_all()
      // vuex store does nothin?
      // this.$store.reset()
      window.localStorage.removeItem('vuex')
      this.reloadNav()
    },
    signOut() {
      this.cleanup()
      window.setTimeout(() => {
        authService.logout()
      }, 250)
    },
    oidcUserError() {
      console.log('oidcUserError')
      this.cleanup()
      window.setTimeout(() => {
        authService
          .login()
          .then(() => {
            console.log('oidcinterceptor igninRedirect success')
          })
          .catch(() => {
            emitter.emit('toastError', { message: $t('idp_unavailable') })
          })
      }, 250)
    },
    upper(item) {
      return item.label[this.languageKey ? this.languageKey : 'de'].toUpperCase()
    },
    gotoExternalUrl(url) {
      window.open(url, '_blank')
    },
    search(e) {
      let routeName = 'masterdata_search'
      if (privileges.has({ path: '/masterdata/find', permission: 'read' })) {
        routeName = 'masterdata_find'
      }
      this.$router
        .push({
          name: 'masterdata_search',
          params: this.$route.params
        })
        .catch((e) => {
          //you need to have a catcher
        })
    },
    setRouteInfo(routeInfo) {
      this.routeInfo = routeInfo.path
      this.setFocusElements(this.navTree, this.routeInfo && this.routeInfo.length ? this.routeInfo : '#nope')
    },
    setFocusElements(arrayOfRouteElements, focusPath) {
      let anyFocus = false
      arrayOfRouteElements.forEach((element) => {
        if (focusPath.replace(/\/\d+/, '/read').search(element.route) > 0) {
          // replace /{id} with /read
          // TRUE :: ("#/de/processingFormTemplates/read").search("/processingFormTemplates")
          element.selectedPath = true
          anyFocus = true
        } else {
          if (element.children && element.children.length > 0) {
            // check the children
            element.selectedPath = this.setFocusElements(element.children, focusPath)
            anyFocus = anyFocus || element.selectedPath
          } else {
            element.selectedPath = false
          }
        }
      })
      return anyFocus
    },
    setNavigationVisibility(arrayOfRouteElements) {
      let somethingVisible = false
      arrayOfRouteElements.forEach((element) => {
        if (element.children && element.children.length > 0) {
          element.visibility = this.setNavigationVisibility(element.children)
        } else {
          // no children

          // do not display betaFeatures
          if (element.visibilityBeta && element.visibilityBeta === true && !this.$getBeta()) {
            element.visibility = false
            return false
          }
          // legacy link
          element.legacy = element.route.substring(0, 7) === '/legacy'
          if (element.legacy && !this.$legacyEnabled()) {
            element.visibility = false
            return false
          }
          // detect path ending with search/read
          if (element.route.substring(element.route.length - '/search'.length) === '/search') {
            const testpath = element.route.substring(0, element.route.length - '/search'.length)
            element.visibility = privileges.has({
              path: testpath,
              permission: 'search'
            })
          } else if (element.route.substring(element.route.length - '/read'.length) === '/read') {
            const testpath = element.route.substring(0, element.route.length - '/read'.length)
            element.visibility = privileges.hasOneOf([
              {
                path: testpath,
                permission: 'read'
              },
              {
                path: testpath,
                permission: 'search'
              }
            ])
          } else {
            element.visibility = privileges.has({
              path: element.route,
              permission: 'read'
            })
          }
        }
        somethingVisible = somethingVisible || element.visibility
      })
      // already returned true above if any element is true
      return somethingVisible
    },
    reloadNav() {
      this.setNavigationVisibility(this.navTree)
    },
    async backToOriginDomain() {
      let legalEntityStore = useLegalEntityStore()
      const domainId = legalEntityStore.getOriginId
      await this.personsService.backToOriginDomain(domainId)
    },
    async backToOriginUser() {
      let legalEntityStore = useLegalEntityStore()
      legalEntityStore.setSelectedApplicationUser(0)
      await this.backToOriginDomain()
    },

    getDomainText: (item) => ` ${item.name1 ? item.name1 : ''} ${item.name2 ? item.name2 : ''} `,
    gotoLegacyNav(route) {
      this.$gotoLegacy(route.split('/')[2], route.split('/')[3])
    },
    routePushHome() {
      this.$router.push({ name: 'home', params: { lang: this.$route.params.lang } }).catch((e) => {
        /* duplicated route */
      })
    }
  },
  watch: {
    $route(to) {
      this.setRouteInfo(to)
    }
  },
  mounted() {
    emitter.on('initialized', (e) => {
      console.log('got an init cmd')
      this.ready = true
    })
    emitter.on('reloadNav', this.reloadNav)
    emitter.on('privilegesNew', this.reloadNav)
    this.reloadNav()
    let legalEntityStore = useLegalEntityStore()
    if (legalEntityStore.applicationUser?.id) {
      this.personsService.init(legalEntityStore.applicationUser, legalEntityStore.persons)
    }

    emitter.on('oidcUserLoaded', this.personsService.oidcUserLoaded)
    emitter.on('oidcUserUnloaded', this.oidcUserError)
    emitter.on('oidcSilentRenewError', this.oidcUserError)
    emitter.on('backendUnAuthorized', (e) => {
      console.log('got an backendUnAuthorized')
      this.oidcUserError()
    })
    emitter.on('routePushHome', this.routePushHome)
  },
  unmounted() {
    emitter.off('reloadNav')
    emitter.off('privilegesNew')
    emitter.off('oidcUserLoaded')
    emitter.off('oidcUserUnloaded')
    emitter.off('oidcSilentRenewError')
    emitter.off('backendUnAuthorized')
    emitter.off('routePushHome')
  }
})
</script>
@/views/profile/version
