import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*  status: string | null = null
 */
@Module({ namespaced: true })
export default class SearchMilkQualityTypeStore extends VuexModule {
  Quality_animalTypeId: string | null = null
  Quality_sampleTypeId: string | null = null
  Quality_sampleStatus: string | null = 'VALID'
  Quality_errorCodeLab: string | null = null
  Quality_dairyIdent: number | null = null
  Quality_primaryKeyLab: string | null = null
  hasInfo: boolean | null = null

  items: TermItemDefinition[] = [
    { key: 'Quality_animalTypeId', operation: ':' },
    { key: 'Quality_sampleTypeId', operation: ':' },
    { key: 'Quality_sampleStatus', operation: ':' },
    { key: 'Quality_errorCodeLab', operation: ':' },
    { key: 'Quality_dairyIdent', operation: ':' },
    { key: 'Quality_primaryKeyLab', operation: ':' },
    { key: 'hasInfo', operation: ':' }
  ]
}
