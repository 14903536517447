/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'

// new shiny pinia store
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import { Store } from 'pinia'

const AllPiniaStores = new Set<Store>()
pinia.use(({ store }) => {
  AllPiniaStores.add(store)

  store.$all = AllPiniaStores

  store.$reset_all = () => store.$all.forEach((s) => s.$reset())

  store.$reset_all_preserve = (except: string[]) =>
    store.$all.forEach((s) => {
      if (!except.includes(s.$id)) {
        s.$reset()
      }
    })
})

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $all: typeof AllPiniaStores
    /** Reset all the stores */
    $reset_all: () => void
    $reset_all_preserve: (except: string[]) => void
    $reset_by_names: (storeNames: string[]) => void
  }
}
import { router } from '../router'

// Types
import type { App } from 'vue'
import { Globals } from '@/plugins/globals'
import { Format } from '@/plugins/format'
import { Rules } from '@/plugins/rules'
import { Privileges } from '@/plugins/privileges'

// old vuex
import oldVuexStore from '@/store'

export function registerPlugins(app: App) {
  app.use(vuetify).use(router).use(pinia).use(Format).use(Globals).use(Privileges).use(Rules).use(oldVuexStore)
}
