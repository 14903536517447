import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*  status: string | null = null
 */
@Module({ namespaced: true })
export default class SearchMilkQualityPeriodStore extends VuexModule {
  Quality_Year: string | null = null
  Quality_Month: string | null = null
  Quality_DateFrom: string | null = null
  Quality_DateUntil: string | null = null
  Quality_From: string | null = null
  Quality_Until: string | null = null

  items: TermItemDefinition[] = [
    { key: 'Quality_Year ', operation: ':' },
    { key: 'Quality_Month ', operation: ':' },
    { key: 'Quality_DateFrom ', operation: '>=' },
    { key: 'Quality_DateUntil', operation: '<=' },
    { key: 'Quality_From', operation: '>=' },
    { key: 'Quality_Until', operation: '<=' }
  ]
}
