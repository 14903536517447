import { defineStore } from 'pinia'
import { LocaleMessages, VueMessageType } from 'vue-i18n'

interface State {
  de: LocaleMessages<VueMessageType> | undefined
  fr: LocaleMessages<VueMessageType> | undefined
  it: LocaleMessages<VueMessageType> | undefined
}

export const useI18nStore = defineStore('i18nStore', {
  state: (): State => ({
    de: undefined,
    fr: undefined,
    it: undefined
  }),
  actions: {
    getTranslation(lang: string) {
      switch (lang) {
        case 'fr':
          return this.fr
        case 'it':
          return this.it
        default:
          return this.de
      }
    },
    setTranslation(lang: string, languageObject: LocaleMessages<VueMessageType>) {
      switch (lang) {
        case 'de':
          this.de = languageObject
          break
        case 'fr':
          this.fr = languageObject
          break
        case 'it':
          this.it = languageObject
          break
      }
    }
  },
  persist: true
})
