import { defineStore } from 'pinia'
import { Privilege } from '@/services/privileges'

interface State {
  privileges: Privilege[]
  legacyApps: []
}

export const usePrivilegesStore = defineStore('privilegesStore', {
  state: (): State => ({
    privileges: [],
    legacyApps: []
  }),
  actions: {
    setPrivileges(privileges: Privilege[]) {
      this.privileges = privileges
    },
    setLegacyApps(legacyApps: []) {
      this.legacyApps = legacyApps
    }
  },
  persist: true
})
