<template>
  <div v-if="$getDebug()">mv:{{ modelValue }}, lcd:{{ localChDate }}, pd: {{ pickerDate }}</div>
  <VueDatePicker
    ref="datepicker"
    v-model="pickerDate"
    :locale="$route.params.langKey"
    :format="dateFormatted"
    :cancelText="$t('cancel')"
    :selectText="$t('choose')"
    :readonly="readonly"
    :clearable="clearable"
    date-picker
    :enable-time-picker="false"
    :teleport="true"
    :alt-position="altPos"
    @date-update="dateUpdate"
    :class="class"
  >
    <template #trigger>
      <v-text-field
        ref="datepickerfield"
        :label="dateLabel"
        prepend-inner-icon="mdi-calendar"
        v-model="localChDate"
        :rules="calcRules()"
        :readonly="readonly"
        :clearable="clearable"
        clear-icon="mdi-close-circle"
        @click:clear="clearMessage"
        @change="change"
        @update="update"
        @keyup.enter="keyupEnter"
        @keyup.esc="keyupEsc"
        density="compact"
        min-width="170"
      ></v-text-field>
    </template>
    <template #action-buttons></template>
  </VueDatePicker>
</template>

<script lang="ts">
import { format } from 'date-fns'

// https://vue3datepicker.com/slots/trigger-and-input/
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    modelValue: { type: String, required: false, default: null },
    dateLabel: { type: String, required: false, default: '' },
    clearable: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false, default: false },
    readonly: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    class: { type: String, required: false }
  },
  data() {
    return {
      localChDate: this.formatDate(this.modelValue),
      elementPos: { top: 0, left: 0 }
    }
  },
  computed: {
    pickerDate: {
      get() {
        if (this.modelValue) {
          const [year, month, day] = this.modelValue.split('-')
          return { year: year, month: month, day: day }
        } else {
          return { year: null, month: null, day: null }
        }
      },
      set(): void {}
    },
    dateFormatted: {
      get() {
        return this.formatDate(this.modelValue)
      },
      set(date) {
        this.localIsoDate = this.parseDate(date)
      }
    }
  },
  emits: ['update:modelValue', 'change', 'keyup', 'keyup.enter'],
  methods: {
    async dateUpdate(date) {
      this.$refs.datepicker.closeMenu()
      this.$emit('update:modelValue', format(date, 'yyyy-MM-dd'))
      this.$emit('change')
      this.localChDate = format(date, 'dd.MM.yyyy')
    },
    keyupEnter() {
      this.$emit('keyup.enter')
    },
    keyupEsc() {
      if (this.modelValue.length === 10) {
        this.localChDate = format(this.modelValue, 'dd.MM.yyyy')
      } else {
        this.localChDate = ''
      }
    },
    change() {
      this.$refs.datepickerfield.validate()
      if (this.$refs.datepickerfield.isValid === true) {
        this.$emit('update:modelValue', this.parseDate(this.localChDate))
        this.$emit('change')
      }
    },
    update() {
      this.$refs.datepickerfield.validate()
    },
    clearMessage() {
      this.$emit('update:modelValue', null)
      this.$emit('change')
    },
    formatDate(date: String | undefined) {
      if (date == undefined) {
        return null
      }
      if (!date) {
        return null
      }
      if (date.length < 7) {
        return null
      }
      if (date.length > 7) {
        const [year, month, dayTime] = date.split('-')
        const [day] = dayTime.split('T')
        return `${day}.${month}.${year}`
      }
    },
    parseDate(date: String | undefined) {
      if (!date) return null
      if (date.length > 7) {
        const [day, month, year] = date.split('.')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
    },
    calcRules() {
      if (this.required == true) {
        return [this.$rules.isCHDate, this.$rules.required]
      } else {
        return [this.$rules.isCHDate]
      }
    },
    altPos(element) {
      //otherwise a picker within a dialog will fail in positioning e.g. rolepicker http://localhost:8080/de/applicationUsers/3
      let bodyRect = document.body.getBoundingClientRect()
      let elemRect = element.getBoundingClientRect()
      return { top: elemRect.bottom - bodyRect.top, left: elemRect.left - bodyRect.left }
    }
  },
  watch: {
    modelValue: {
      handler(value) {
        this.localChDate = this.formatDate(value)
        //console.log('mv changed', value)
      }
    }
  }
})
</script>
