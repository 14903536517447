import { defineStore } from 'pinia'
import { LegalEntities } from '@/domain/LegalEntities'
import { personsService } from '@/services/persons'

interface State {
  applicationUser: Object
  legalEntities: LegalEntities[]
  selectedPersonId: number | null
  selectedPerson: any
  selectedLegalEntityId: number | null
  selectedLegalEntity: any
  selectedApplicationUserId: number
  selectedApplicationUserName: string
  originLegalEntityId: number
  domSwitchLegalEntity: any
  loading: boolean // Service / persons
  loaders: number // Service / persons
}

export const useLegalEntityStore = defineStore('legalEntityStore', {
  state: (): State => ({
    applicationUser: {},
    legalEntities: [],
    selectedPersonId: 0, // chosen LegalEntitie
    selectedPerson: {},
    selectedLegalEntityId: 0,
    selectedLegalEntity: {},
    selectedApplicationUserId: 0,
    selectedApplicationUserName: '',
    originLegalEntityId: 0,
    domSwitchLegalEntity: {},
    loading: false,
    loaders: 0
  }),
  getters: {
    //getSelectedPerson: (state) => state.selectedPerson,
    getSelectedPersonId: (state) => state.selectedLegalEntityId,
    getSelectedLegalEntityId: (state) => state.selectedLegalEntityId,
    getSelectedApplicationUserId: (state) => state.selectedApplicationUserId,
    hasDomainSwitch: (state) => state.selectedLegalEntityId != state.originLegalEntityId,
    getOriginId: (state) => state.originLegalEntityId,
    getOriginLegalEntityId: (state) => state.originLegalEntityId,
    getDomainName: (state) => {
      let legalEntityName = ''
      if (state.selectedLegalEntityId === state.originLegalEntityId) {
        legalEntityName = state.selectedPerson?.name1
        if (state.selectedPerson?.name2) legalEntityName += ' ' + state.selectedPerson?.name2
      } else {
        legalEntityName = state.domSwitchLegalEntity.legalEntityName1
        if (state.domSwitchLegalEntity.legalEntityName2) legalEntityName += ' ' + state.domSwitchLegalEntity.legalEntityName2
      }
      return legalEntityName
    },
    getApplicationUser: (state) => state.applicationUser
  },
  actions: {
    incLoaders() {
      this.loaders++
      this.loading = this.loaders > 0
    },
    decLoaders() {
      this.loaders--
      this.loading = this.loaders > 0
    },
    setApplicationUser(applicationUser: Object) {
      this.applicationUser = applicationUser
    },
    setLegalEntities(le: LegalEntities[]) {
      this.legalEntities = le.sort((a, b) => (a.name1 > b.name1 ? 1 : b.name1 > a.name1 ? -1 : 0))
    },
    setDomain(newLegalEntityId: number | null) {
      /* only for direct connections to LegalEntity, do not use this for domain-switching */
      this.selectedLegalEntityId = newLegalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
      if (!this.selectedApplicationUserId) {
        // only set originLegalEntityId when the user is not in userswitch mode
        this.originLegalEntityId = newLegalEntityId
      }
      this.selectedLegalEntity = this.legalEntities.filter((pers) => pers.id == newLegalEntityId)[0]
      this.selectedPerson = this.selectedLegalEntity
    },
    setSelectedApplicationUser(selectedApplicationUser: any) {
      // test if name is digits only, if yes, display email instead
      this.selectedApplicationUserName = /^\d+$/.test(selectedApplicationUser.name) ? selectedApplicationUser.email : selectedApplicationUser.name
      this.selectedApplicationUserId = selectedApplicationUser.id
      /* only for direct connections to LegalEntity, do not use this for domain-switching */
      if (selectedApplicationUser.id) {
        // copy to domainswitch
        this.originLegalEntityId = 1
        this.selectedLegalEntityId = selectedApplicationUser.allowedLegalEntities[0].id
        this.selectedPersonId = this.selectedLegalEntityId
        this.domSwitchLegalEntity = { ...this.selectedLegalEntity }
        this.selectedLegalEntity = {}
        personsService.legalEntities = selectedApplicationUser.allowedLegalEntities
        this.legalEntities = selectedApplicationUser.allowedLegalEntities
      } else {
        //restore from domainswitch
        this.selectedLegalEntityId = 1
        this.selectedPersonId = this.selectedLegalEntityId
        this.originLegalEntityId = 1
        this.selectedLegalEntity = { ...this.domSwitchLegalEntity }
        this.domSwitchLegalEntity = {}
        this.legalEntities = []
      }
    },
    switchDomain(legalEntity: any) {
      this.domSwitchLegalEntity = legalEntity
      this.selectedLegalEntityId = legalEntity.legalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
    },
    resetDomain() {
      this.selectedLegalEntityId = this.originLegalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
    }
  },
  persist: true
})
