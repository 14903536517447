import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*  status: string | null = null
 */
@Module({ namespaced: true })
export default class SearchMilkQualityRatingStore extends VuexModule {
  Quality_ThresholdExeededLab: string | null = null
  Quality_Complaints: string | null = null
  Quality_ComplaintsCriterion: string | null = null
  Quality_DeliveryStopLab: string | null = null

  items: TermItemDefinition[] = [
    { key: 'Quality_ThresholdExeededLab', operation: ':' },
    { key: 'Quality_Complaints', operation: ':' },
    { key: 'Quality_ComplaintsCriterion ', operation: ':' },
    { key: 'Quality_DeliveryStopLab ', operation: ':' }
  ]
}
