import { TermItemDefinition } from '@/services/term'
import { Module, VuexModule } from 'vuex-module-decorators'

/*  status: string | null = null
 */
@Module({ namespaced: true })
export default class SearchMilkQualityValueStore extends VuexModule {
  Quality_Germs: number | null = null
  Quality_Cells: number | null = null
  Quality_Inibitor: string | null = null
  Quality_FreezingPoint: number | null = null
  Quality_FattyAcid: number | null = null
  Quality_Clostridia: number | null = null

  items: TermItemDefinition[] = [
    { key: 'Quality_Germs', operation: '>=' },
    { key: 'Quality_Cells', operation: '>=' },
    { key: 'Quality_Inibitor', operation: ':' },
    { key: 'Quality_FreezingPoint', operation: '>=' },
    { key: 'Quality_FattyAcid', operation: '>=' },
    { key: 'Quality_Clostridia', operation: '>=' }
  ]
}
