import { backendService, JavaPageOptions, JavaPageOptionsType } from '@/services/BackendService'

export default {
  methods: {
    routerPushSize(size: number): void {
      const route = this.$route
      const router = this.$router
      router.push({ path: route.path, query: { ...route.query, size: size } }).catch((e) => {
        console.log('backendservice.setPage route duplicated', e)
      })
    },
    routerPushPage(page: number): void {
      const route = this.$route
      const router = this.$router
      router.push({ path: route.path, query: { ...route.query, page: page - 1 } }).catch((e) => {
        console.log('backendservice.setPage route duplicated', e)
      })
    },
    routerPushSort(sort: any): void {
      const route = this.$route
      const router = this.$router
      router.push({ path: route.path, query: { ...route.query, sort: sort } }).catch((e) => {
        console.log('backendservice.setPage route duplicated', e)
      })
    },
    routerPushDTSort(sortBy: any) {
      const route = this.$route
      const router = this.$router
      router.push({ path: route.path, query: { ...route.query, sort: backendService.getJavaSort(sortBy) } }).catch((e) => {
        console.log('backendservice.setPage route duplicated', e)
      })
    },
    getJavaPageOptions(defaultOptions: undefined | JavaPageOptions): JavaPageOptionsType {
      const route = this.$route
      const jpo = new JavaPageOptions()
      //jpo.sort = [] // otherwise its type function???

      // default values
      if (defaultOptions !== undefined) {
        if (defaultOptions?.size) jpo.size = defaultOptions.size
        if (defaultOptions?.page) jpo.page = defaultOptions.page
        if (defaultOptions?.sort) jpo.sort = defaultOptions.sort
      }

      //overwrite with url.query values
      if (route.query?.size) jpo.size = route.query.size
      if (route.query?.page) jpo.page = route.query.page
      if (route.query?.sort) jpo.sort = route.query.sort
      return jpo
    },
    toDTSort(sort: string[] | undefined) {
      return backendService.getVuetifyDTSort(sort)
    },
    toJavaSort(sort: any) {
      return backendService.getJavaSort(sort)
    },
    getDTPageFromRoute(page: undefined | string): number {
      if (!page) return 0
      return parseInt(page) + 1
    },
    checkPage(totalElements: number): number {
      const route = this.$route

      // size 10  * page 0  = 0    totalElements = 6    do nothing
      // size 10  * page 1  = 10   totalElements = 16   do nothing
      // size 10  * page 1  = 10   totalElements = 6   totelelements out or visible range, reroute
      if (route.query.size * route.query.page > totalElements) {
        //something went wrong page is out of totalElements reset to 0
        this.routerPushPage(1)
      }
      return totalElements
    }
  }
}
