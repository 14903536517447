import { apiURL, app, emitter } from '@/main'
import { i18n } from '@/i18n'
import { NavigationGuardNext } from 'vue-router'
import { capitalize, nameLocale } from '../utils/various'
import { useI18nStore } from '@/store/I18nStore'
import { showError } from './axios'
import axios from 'axios'

export const Languages: string[] = ['de', 'fr', 'it']
export const defaultLanguage = Languages.includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : Languages[0]

export type upLang = 'De' | 'Fr' | 'It'
export type isoLang = 'de-ch' | 'fr-ch' | 'it-ch'
export type nameKey = 'nameDe' | 'nameFr' | 'nameIt'
export type upNameKey = 'NameDe' | 'NameFr' | 'NameIt'

class Language {
  private static _instance: Language
  public isoLangKey: isoLang = 'de-ch' //nameLocale(to.params.lang) + '-ch' //de-ch
  public upLang: upLang = 'De' //capitalize(to.params.lang) //De
  public langKey: nameKey = 'nameDe' //nameLocale(to.params.lang) //nameDe
  public upLangKey: upNameKey = 'NameDe' //capitalize(nameLocale(to.params.lang)) //NameDe

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  /* i'm registered in Router beforeEach */
  async routeIntercepter(to: Route, from: Route, next: NavigationGuardNext): Promise<any> {
    console.log('language routeIntercepter testing', to, from, defaultLanguage)
    if (to.name == 'oidcCallback') {
      //do not intercept oidcclient
      next()
      return
    }

    if (!Languages.includes(to.params.lang)) {
      next({
        name: to.name ?? 'home',
        params: { lang: defaultLanguage },
        query: to.query,
        hash: to.hash
      })
      return //only call next once
    }

    app.axios.defaults.headers.common['Accept-Language'] = to.params.lang
    emitter.emit('setLocale', to.params.lang)

    await language.loadTranslations(to.params.lang, false)

    language.isoLangKey = <isoLang>nameLocale(to.params.lang) + '-ch' //de-ch
    language.upLang = <upLang>capitalize(to.params.lang) //De
    language.langKey = <nameKey>nameLocale(to.params.lang) //nameDe
    language.upLangKey = <upNameKey>capitalize(nameLocale(to.params.lang)) //NameDe

    to.params.isoLangKey = language.isoLangKey
    to.params.upLang = language.upLang
    to.params.langKey = language.langKey
    to.params.upLangKey = language.upLangKey
    next()
    return
  }

  //forced reload after frontend ui, language change
  async loadTranslations(lang: string, forced: boolean) {
    const i18nStore = useI18nStore()
    const storedTranslation = i18nStore.getTranslation(lang)
    if (!forced && storedTranslation) {
      try {
        console.log('loadTranslations translation detected')
        i18n.global.setLocaleMessage(lang, storedTranslation)
        i18n.global.locale.value = lang
      } catch (e) {
        console.log('loadTranslations i18n settings', e)
      }
      return storedTranslation
    }
    try {
      emitter.emit('setLanguageLoading', true)
      const result: any = await axios.get(apiURL + '/translations/language/' + lang, {
        params: { language: lang }
      })
      i18n.global.setLocaleMessage(lang, result.data)
      i18n.global.locale.value = lang
      i18nStore.setTranslation(lang, result.data)
      return result.data
    } catch (e) {
      console.log('loadTranslations', e)
      showError(e)
    } finally {
      emitter.emit('setLanguageLoading', false)
    }
  }

  showLanguageKeys() {
    const keys = Object.keys(i18n.global.getLocaleMessage(i18n.global.locale.value))
    keys.forEach((key) => {
      i18n.global.setLocaleMessage(i18n.global.locale.value, { key: key })
    })
  }
}

export const language = Language.Instance
